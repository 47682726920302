"use client";

import { TypeGenericSection } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { analytics } from "@yardzen/next-segment";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  H3,
  Input,
  Small,
} from "@yardzen/ui";
import gql from "graphql-tag";
import * as React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSyncUserToCordialMutation } from "../../gql/apollo";
export const syncUserToCordialMutation = gql`
  mutation SyncUserToCordial($input: SyncUserToCordialInput!) {
    syncUserToCordial(input: $input) {
      email
    }
  }
`;

export const EmailCapture: React.FC<{
  emailCapture: TypeGenericSection<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}> = ({ emailCapture, segmentFlow }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [syncUserToCordial] = useSyncUserToCordialMutation();

  const handleEmailSubmit = async (data: { email: string }) => {
    setIsSubmitting(true);
    await analytics.trackFormSubmitted({
      flow_name: segmentFlow,
      form_name: "Email Capture",
    });

    await syncUserToCordial({
      variables: {
        input: {
          email: data.email,
        },
      },
    });

    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  const emailSchema = z.object({
    email: z.string().email("Invalid email address"),
  });

  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(emailSchema),
  });

  return (
    <div className="bg-brand-secondary-dark flex flex-col justify-center gap-4 p-8 text-white md:flex-row">
      <H3>{emailCapture.fields.title}</H3>
      <div className="flex flex-col gap-2">
        {!isSubmitted ? (
          <>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handleEmailSubmit)}
                className="flex gap-2"
              >
                <FormField
                  control={form.control}
                  name="email"
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Email"
                          type="email"
                          {...field}
                          className="w-[15rem] flex-1 rounded px-4 py-2 text-black md:w-[18rem]"
                          tracking={{
                            flow_name: segmentFlow,
                            form_name: "Marketing Email Capture",
                            text_value: field.value,
                            field_name: "Email Address",
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting || !form.formState.isValid}
                  className="rounded bg-white px-4 py-2 text-gray-900 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400"
                  startIcon={<FontAwesomeIcon icon={faArrowRight} />}
                />
              </form>
            </Form>
            <Small className="mt-1">{emailCapture.fields.subtitle}</Small>
          </>
        ) : (
          <div>
            <Small>
              {emailCapture.fields.CTAs?.[0]?.fields?.successMessage}
            </Small>
          </div>
        )}
      </div>
    </div>
  );
};
