"use client";

import { TypeAOrB } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@yardzen/next-client-util";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { ComponentPropsWithoutRef, forwardRef, useState } from "react";
import { Icon } from "./icon/Icon";
import { RichText } from "./rich-text/RichText";

export function AOrB({
  section,
  slug,
}: {
  section: TypeAOrB<"WITHOUT_UNRESOLVABLE_LINKS">;
  slug?: string;
}) {
  const left = section.fields.choices[0];
  const right = section.fields.choices[1];

  const [focus, setFocus] = useState<"A" | "B" | null>(null);

  if (!left || !right) {
    return null;
  }
  const { title, showArrow } = section.fields;

  return (
    <section
      className={cn(
        "relative mx-auto flex flex-col bg-[#F6F6F6] transition-all",
        "gap-4 p-2 py-4",
        "sm:gap-8 sm:p-4 sm:py-8",
        "md:gap-16 md:p-8 md:py-16",
        "lg:gap-32 lg:p-16 lg:py-32",
      )}
    >
      {title && <h1 className="font-display text-center text-3xl">{title}</h1>}
      <div className="grid grid-cols-2 items-center justify-center gap-4 md:gap-8 lg:gap-12">
        <ChoiceCardLink
          choice={left}
          fromPage={slug}
          className="md:justify-self-end"
          onFocus={() => setFocus("A")}
          onBlur={() => setFocus(null)}
          onMouseEnter={() => setFocus("A")}
          onMouseLeave={() => setFocus(null)}
        />
        <ChoiceCardLink
          choice={right}
          fromPage={slug}
          className="md:justify-self-start"
          onFocus={() => setFocus("B")}
          onBlur={() => setFocus(null)}
          onMouseEnter={() => setFocus("B")}
          onMouseLeave={() => setFocus(null)}
        />
        {showArrow && (
          <FontAwesomeIcon
            icon={faArrowRight}
            className={cn(
              "absolute bottom-0 left-0 right-0 top-0 m-auto h-10 w-10 transition-all ease-in-out",
              focus === "A"
                ? "-rotate-180"
                : focus === "B"
                  ? "rotate-0"
                  : "-rotate-90",
              focus === null ? "opacity-0" : "opacity-100",
            )}
          />
        )}
      </div>
    </section>
  );
}

function urlWithParams(url: string, params: Record<string, string>): string {
  const newUrl = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    newUrl.searchParams.append(key, value);
  });
  return newUrl.toString();
}

const ChoiceCardLink = forwardRef<
  Omit<HTMLAnchorElement, "href">,
  {
    choice: TypeAOrB<"WITHOUT_UNRESOLVABLE_LINKS">["fields"]["choices"][0];
    fromPage?: string;
  } & Omit<ComponentPropsWithoutRef<typeof Link>, "href">
>(({ choice, fromPage, ...props }, ref) => {
  const isSpinny = useSearchParams().get("spinny") === "true";
  if (!choice) {
    return null;
  }
  const { title, url, body, icon, eyebrow } = choice.fields;
  const params = fromPage ? { fromPage } : ({} as Record<string, string>);
  const href = urlWithParams(url, params);
  return (
    <Link
      ref={ref as React.Ref<HTMLAnchorElement>}
      {...props}
      href={href}
      className={cn(
        "hover:border-black-elderberry flex max-w-lg flex-col items-center justify-center self-stretch rounded-2xl bg-white shadow transition-all hover:bg-[#F2FFE5] focus:bg-[#F2FFE5]",
        "gap-2 p-2 sm:p-4 md:gap-4 md:p-6 lg:gap-6 lg:p-8",
        props.className,
      )}
    >
      {icon && (
        <Icon
          icon={icon}
          className={cn(
            "h-10 w-10 sm:h-16 sm:w-16 md:h-20 md:w-20",
            isSpinny ? "animate-spin" : "",
          )}
        />
      )}
      {eyebrow && (
        <div className="bg-zest rounded-full px-2 py-1 text-center text-xs">
          {eyebrow}
        </div>
      )}
      <h2 className="font-display text-center text-3xl">{title}</h2>
      {body && <RichText className="" content={body} />}
    </Link>
  );
});

ChoiceCardLink.displayName = "ChoiceCardLink";
