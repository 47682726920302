"use client";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  isTypePackageDetailItem,
  TypeMultiPackageTeaser,
} from "@contentful/types";
import { notEmpty } from "@yardzen/js-util";
import { Button, Eyebrow, H1, Link } from "@yardzen/ui";
import { cva } from "class-variance-authority";
import { ListItemCollection } from "./ListItemCollection";
import { PackageTeaserItem } from "./PackageTeaserItem";

export const MultiPackageTeaser: React.FC<{
  multiPackageTeaser: TypeMultiPackageTeaser<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}> = ({ multiPackageTeaser, segmentFlow }) => {
  const {
    moduleHeading,
    moduleSubheading,
    listItems,
    packages,
    displayItems,
    moduleCta,
    eyebrow,
  } = multiPackageTeaser?.fields || {};

  // discription field is only displayed when there are 2 or fewer teaser items shown
  const displayDescription =
    displayItems &&
    displayItems.findIndex((item) => item === "package-description") > -1;

  const packageTeaserCardVariant = listItems ? "with-list" : "no-list";
  const packageTeaserCardVariants = cva("", {
    variants: {
      backgroundContainer: {
        "with-list":
          "bg-brand-moss-greens-10 w-full max-w-7xl rounded-lg bg-opacity-50 p-4 max-md:max-w-full md:px-5 md:py-8",
        "no-list": "w-full max-w-7xl max-md:max-w-full py-4 md:py-8",
      },
      gridContainer: {
        "with-list": "grid grid-cols-1 justify-center gap-2 lg:grid-cols-7",
        "no-list": `grid grid-cols-1 justify-center gap-2 lg:grid-cols-${packages ? packages.length : 1}`,
      },
      packageCardsContainer: {
        "with-list":
          "flex flex-col justify-center gap-4 md:flex-row lg:col-span-4",
        "no-list":
          "flex flex-col justify-center gap-4 md:flex-row lg:col-span-full",
      },
    },
  });

  return (
    <div className="mt-5 flex flex-col items-center justify-center px-1 max-md:mt-4 md:px-8">
      <div className="flex flex-col items-center text-center">
        {moduleHeading && (
          <H1 className="text-brand-primary-darker px-4 pt-12 text-4xl md:text-4xl">
            {moduleHeading}
          </H1>
        )}
        {moduleSubheading && (
          <div className="text-brand-brown-black w-[90%] max-w-[65%] pb-10 pt-4 text-lg opacity-75 md:w-[100%] md:min-w-[37%] md:max-w-[100%]">
            {documentToReactComponents(moduleSubheading)}
          </div>
        )}
      </div>
      <div
        className={packageTeaserCardVariants({
          backgroundContainer: packageTeaserCardVariant,
        })}
      >
        {eyebrow && <Eyebrow text={eyebrow} className="my-2" />}
        <div
          className={packageTeaserCardVariants({
            gridContainer: packageTeaserCardVariant,
          })}
        >
          {listItems && (
            <ListItemCollection
              listItemCollection={listItems}
              className="gap-2 lg:col-span-3"
              listItemHeadingClassName="text-brand-secondary-dark text-sm leading-none md:text-base mx-1 md:mx-0"
              listItemsAtBottom={true}
            />
          )}
          <div
            className={packageTeaserCardVariants({
              packageCardsContainer: packageTeaserCardVariant,
            })}
          >
            {packages &&
              packages
                .filter(notEmpty)
                .filter(isTypePackageDetailItem)
                .map((packageTeaserItem, i) => (
                  <PackageTeaserItem
                    key={`package-teaser-item-${i}`}
                    packageTeaserItem={packageTeaserItem}
                    segmentFlow={segmentFlow}
                    displayDescription={displayDescription}
                  />
                ))}
          </div>
        </div>
      </div>
      {moduleCta && (
        <div className="flex justify-center p-8 md:p-12">
          <Button
            variant="outline"
            className="mx-2 border-opacity-20"
            asChild
            tracking={{
              button_name: "Multi Packager Teaser CTA",
              button_content: moduleCta?.fields.buttonText,
              flow_name: segmentFlow,
            }}
          >
            <Link
              className="hidden whitespace-nowrap px-8 py-4 text-base font-semibold max-[365px]:whitespace-normal max-[365px]:leading-3 min-[360px]:inline-flex"
              href={moduleCta?.fields.url}
            >
              {moduleCta?.fields.buttonText}
            </Link>
          </Button>
        </div>
      )}
    </div>
  );
};
