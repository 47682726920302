import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { Block, BLOCKS, Document, Inline } from "@contentful/rich-text-types";
import { cn } from "@yardzen/next-client-util";
import { forwardRef, ReactNode } from "react";

interface RichTextProps {
  content: Document;
  className?: string;
}

const RichText = forwardRef<HTMLDivElement, RichTextProps>(
  ({ content, className }, ref) => {
    const options: Options = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => {
          return <ul className="ml-4 list-disc">{children}</ul>;
        },
      },
    };

    return (
      <div ref={ref} className={cn(className)}>
        {documentToReactComponents(content, options)}
      </div>
    );
  },
);

RichText.displayName = "RichText";

export { RichText };
