"use client";

import { TypeReviews } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { analytics } from "@yardzen/next-segment";
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  H3,
  P,
  SegmentClickTypes,
} from "@yardzen/ui";
import { useRouter } from "next/navigation";

export function Reviews({
  reviews,
  segmentFlow,
}: {
  reviews: TypeReviews<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  const router = useRouter();
  const reviewsArray = reviews.fields.reviews;
  const primaryCta = reviews.fields.CTAs?.find(
    (cta) => cta?.fields?.variant === "default",
  );

  return (
    <div className="bg-brand-beige-light mb-4 flex flex-col gap-4 px-8 py-12 md:py-24">
      <div className="flex flex-col justify-center md:flex-row md:justify-between">
        <div className="flex max-w-[850px] flex-col items-center gap-2 text-center md:items-start md:text-start">
          <div className="flex flex-row gap-1 md:items-start">
            <FontAwesomeIcon
              icon={faStar}
              className="fill-[#E19B34] text-xl text-[#E19B34]"
            />
            <FontAwesomeIcon
              icon={faStar}
              className="fill-[#E19B34] text-xl text-[#E19B34]"
            />
            <FontAwesomeIcon
              icon={faStar}
              className="fill-[#E19B34] text-xl text-[#E19B34]"
            />
            <FontAwesomeIcon
              icon={faStar}
              className="fill-[#E19B34] text-xl text-[#E19B34]"
            />
            <FontAwesomeIcon
              icon={faStar}
              className="fill-[#E19B34] text-xl text-[#E19B34]"
            />
          </div>
          <H3 className="font-display max-w-[850px] text-3xl md:text-5xl">
            {reviews.fields.title}
          </H3>
        </div>
        <div className="mt-4 flex flex-col justify-center md:mt-0">
          <Button
            variant="default"
            className="mx-auto w-fit rounded-full px-6 py-4"
            onClick={() => {
              const url = primaryCta?.fields.url;
              if (url) {
                analytics.trackClicked({
                  button_content: primaryCta?.fields.buttonText ?? "",
                  flow_name: segmentFlow,
                  button_name: primaryCta?.fields.buttonText ?? "",
                  click_type: SegmentClickTypes.BUTTON,
                });
                router.push(url);
              }
            }}
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          >
            {primaryCta?.fields.buttonText}
          </Button>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden py-4">
        <Carousel
          className="px-4"
          opts={{
            loop: true,
          }}
        >
          <CarouselContent className="pb-6" aria-live="off">
            {reviewsArray.map((review) => {
              return (
                <CarouselItem
                  key={review?.sys.id}
                  className="basis-full md:basis-1/3 lg:basis-1/4 xl:basis-1/5"
                >
                  <Card
                    key={review?.sys.id}
                    name={review?.fields.name ?? ""}
                    quote={review?.fields.review ?? ""}
                    location={review?.fields.location ?? ""}
                    packageName={review?.fields.package ?? ""}
                  />
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselDots
            dotClassName="border-brand-secondary-dark h-2.5 w-2.5 rounded-full"
            selectedDotClassName="border-brand-secondary-dark bg-brand-secondary-dark h-2.5 w-2.5 rounded-full"
            tracking={{
              button_content: "Yardzen yards Carousel dots",
              flow_name: segmentFlow,
              button_name: "Yardzen yards Carousel dots",
            }}
            count={reviewsArray.length}
          />
        </Carousel>
      </div>
    </div>
  );
}

function Card({
  name,
  quote,
  location,
  packageName,
}: {
  image?: React.ReactNode;
  name: string;
  quote: string;
  location: string;
  packageName: string;
}) {
  return (
    <div className="text-black-elderberry relative flex h-[180px] min-w-[285px] max-w-[334px] shrink-0 flex-col justify-between gap-2 rounded bg-[#FAFFF8] p-[18px]">
      <P className="text-xs text-zinc-800">{quote}</P>
      <div className="nowrap flex flex-col justify-between gap-1 align-bottom">
        <P className="font-display text-2xl">{name}</P>
        <P className="mt-2 text-xs font-semibold" noSpacing={true}>
          {packageName}{" "}
          <FontAwesomeIcon className="mb-[1px] text-[5px]" icon={faCircle} />{" "}
          {location}
        </P>
      </div>
    </div>
  );
}
