"use client";

import { analytics } from "@yardzen/next-segment";
import { FormSubmittedEvent } from "@yardzen/segmentv2";
import {
  Button,
  Input,
  SegmentClickTypes,
  SegmentInputTypes,
  P,
} from "@yardzen/ui";
import { FormEvent, HTMLAttributes, useState } from "react";

export type ProductEmailFormProps = Omit<
  HTMLAttributes<HTMLFormElement>,
  "onSubmit"
> & {
  tracking: InstanceType<typeof FormSubmittedEvent>;
  waitForTracking?: boolean;
  onSubmit?: (e: FormEvent) => Promise<void>;
  success: string;
};
export function ProductEmailForm({
  tracking,
  onSubmit,
  success,
  ...props
}: ProductEmailFormProps) {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (isSubmitted) {
    return (
      <div className="pt-6">
        <P>
          {success
            ? success
            : "We have received your information and will be in touch soon!"}
        </P>
      </div>
    );
  }
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const promise = Promise.all([
          analytics.trackFormSubmitted(tracking),
          analytics.typedIdentify({
            email: email,
          }),
        ]);
        if (props.waitForTracking) {
          await promise;
        }

        await onSubmit?.(e);
        setIsSubmitted(true);
      }}
      className="text-black-elderberry relative grid gap-2"
    >
      <Input
        tracking={{
          flow_name: tracking.flow_name,
          form_name: tracking.form_name,
          field_name: "Product Email Field",
          input_type: SegmentInputTypes.TEXT,
        }}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        className="mb-1 mt-3 w-full border-2 pr-12"
        placeholder="Enter Email"
      />

      <div className="flex">
        <Button
          className="ml-auto px-6 py-2"
          variant="default"
          tracking={{
            flow_name: tracking.flow_name,
            button_name: "Hero Email Submit",
            click_type: SegmentClickTypes.BUTTON,
            button_content: "Text",
          }}
          aria-label="Sign Up"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}
