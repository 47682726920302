"use client";

import { TypeSelectOptionSkeleton } from "@contentful/types";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@yardzen/next-client-util";
import { Entry } from "contentful";
import Image from "next/image";
import { useState } from "react";

export function ChoiceBoxOption({
  entry,
}: {
  entry: Entry<TypeSelectOptionSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
}) {
  const imgSrc = entry.fields.image?.fields.file?.url;
  const [selected, setSelected] = useState(false);
  return (
    <button
      role="option"
      value={entry.fields.value}
      aria-selected={selected}
      onClick={() => setSelected(!selected)}
      className={cn(
        "relative m-5 h-60 w-60 cursor-pointer overflow-hidden rounded-md transition-all duration-300",
        "hover:scale-105 hover:shadow-lg",
        "outline-blue-fescue outline-2",
        "ring-button-primary-disabled ring-4",
        { "ring-button-primary": selected },
      )}
    >
      <input
        type="hidden"
        name={entry.fields.value}
        value={selected ? entry.fields.value : ""}
      />
      {imgSrc && (
        <Image src={`https:${imgSrc}`} alt="" fill className="rounded-md" />
      )}
      <div
        className={cn(
          "bg-button-primary-disabled absolute right-2 top-2 flex h-10 w-10 items-center justify-center rounded-full border-2",
          selected && "bg-button-primary",
        )}
      >
        <FontAwesomeIcon
          icon={faCheck}
          className={cn(
            "h-8 w-8 text-white opacity-0 transition-all duration-300",
            selected && "opacity-100",
          )}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-white/90 p-2">
        {entry.fields.label}
      </div>
    </button>
  );
}
