"use client";

import { TypeHeroSkeleton, TypeImageSkeleton } from "@contentful/types";
import { customImageLoader } from "@yardzen/next-client-util";
import { Button, H1 } from "@yardzen/ui";
import { Entry } from "contentful";
import Image from "next/image";
import { useRouter } from "next/navigation";

export function ImagesGrid({
  section,
  segmentFlow,
}: {
  section: Entry<TypeHeroSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
  segmentFlow: string;
}) {
  const router = useRouter();

  const heading = section?.fields?.subtitle;
  const images = section?.fields?.images;
  const ctas = section?.fields?.CTAs;

  return (
    <div className="relative">
      <div className="text-brand-primary-darker mx-auto flex h-full w-full max-w-7xl flex-col px-6 py-10">
        <div className="flex flex-col justify-between gap-8 pb-10 sm:flex-row">
          <H1 className="w-full text-center text-4xl leading-[120%]">
            {heading}
          </H1>
        </div>

        <div className="grid grid-cols-2 gap-4 pb-10 md:grid-cols-4">
          {images &&
            images.map(
              (
                image:
                  | Entry<
                      TypeImageSkeleton,
                      "WITHOUT_UNRESOLVABLE_LINKS",
                      string
                    >
                  | undefined,
              ) => (
                <div className="flex flex-col gap-2" key={image?.sys.id}>
                  <Image
                    loader={customImageLoader}
                    src={image?.fields.image?.fields?.file?.url ?? ""}
                    alt={image?.fields.image?.fields?.description ?? ""}
                    width={
                      image?.fields.image?.fields?.file?.details?.image
                        ?.width ?? 300
                    }
                    height={
                      image?.fields.image?.fields?.file?.details?.image
                        ?.height ?? 360
                    }
                    className="h-auto w-full rounded-lg object-cover"
                    loading="lazy"
                  />
                </div>
              ),
            )}
        </div>

        <div className="flex flex-col justify-center gap-2 sm:flex-row">
          {ctas?.map((cta) => (
            <Button
              key={cta?.sys.id}
              variant={cta?.fields.variant}
              className="w-full sm:w-auto"
              tracking={{
                button_content: cta?.fields.buttonText ?? "",
                button_name: `Studio McGee Outdoor Collection CTA - ${cta?.fields.title}`,
                flow_name: segmentFlow,
              }}
              onClick={() => {
                if (!cta?.fields.url) return;
                router.push(cta.fields.url);
              }}
            >
              {cta?.fields.buttonText}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
