"use client";

import { TypeListItem, TypeListItemCollection } from "@contentful/types";
import { notEmpty } from "@yardzen/js-util";
import { SegmentFlows } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  cn,
  H1,
  H5,
  P,
  SegmentClickTypes,
} from "@yardzen/ui";
import Image from "next/image";
import { useState } from "react";

export function ListItemCollectionAccordian({
  listItemCollection,
  listItemHeadingClassName,
  listItemSubHeadingClassName,
  segmentFlow,
}: {
  listItemCollection: TypeListItemCollection<"WITHOUT_UNRESOLVABLE_LINKS">;
  listItemHeadingClassName?: string;
  listItemSubHeadingClassName?: string;
  segmentFlow?: SegmentFlows;
}) {
  const { listItems, heading } = listItemCollection.fields ?? {};

  // First is default open
  const [openItems, setOpenItems] = useState<string[]>([
    listItems?.filter(notEmpty).at(0)?.fields.name ?? "",
  ]);
  const [chosenListItem, setChosenListItem] = useState<
    TypeListItem<"WITHOUT_UNRESOLVABLE_LINKS"> | undefined
  >(listItems?.at(0));

  const chooseListItem = (items: string[]) => {
    // If value is only in items it was added. Set it as the image.
    const newItem = items.filter((i) => !openItems.includes(i))?.at(0);
    if (newItem) {
      const newListItem = listItems?.find((li) => li?.fields.name === newItem);
      setChosenListItem(newListItem);

      // Analytics on click
      if (newListItem) {
        analytics.trackClicked({
          flow_name:
            segmentFlow ??
            listItemCollection?.fields.name ??
            "ListItemCollectionAccordian",
          button_name: newListItem.fields.name ?? "",
          button_content: newListItem.fields.heading,
          click_type: SegmentClickTypes.CAROUSEL,
        });
      }
    }
    // Which changed?
    setOpenItems(items);
  };

  return (
    <div className="flex flex-col items-center justify-center px-1 md:px-8">
      <div className="mt-4 w-full max-w-7xl p-4 max-md:mt-10 max-md:max-w-full md:p-8 md:px-16 md:pb-12 md:pt-20">
        {heading && (
          <H1 className="mb-5 lg:text-6xl">
            {listItemCollection?.fields.heading}
          </H1>
        )}
        <div className="grid grid-cols-1 justify-center gap-8 md:grid-cols-2 md:gap-16">
          <div className="grid grid-cols-1">
            <Accordion
              type="multiple"
              value={openItems}
              onValueChange={chooseListItem}
              className="w-full"
            >
              {listItems &&
                listItems.filter(notEmpty).map((item, i) => (
                  <AccordionItem
                    key={item.sys.id}
                    value={item.fields.name ?? ""}
                  >
                    <AccordionTrigger className="py-5 hover:no-underline">
                      <div className="flex flex-row items-center gap-4">
                        <div className="h-5 w-5 rounded-full bg-white p-0.5 text-xs text-black">
                          {i + 1}
                        </div>
                        <H5
                          className={cn(
                            "text-brand-secondary-darker text-left tracking-normal",
                            listItemHeadingClassName,
                          )}
                        >
                          {item.fields.heading}
                        </H5>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="pb-1">
                        <P
                          className={cn(
                            "text-brand-brown-black text-base",
                            listItemSubHeadingClassName,
                          )}
                        >
                          {item.fields.subheading}
                        </P>
                        <div className="w-100 relative m-auto h-full pb-4 pt-6 md:hidden">
                          <Image
                            src={`https:${item?.fields.imageCallout?.fields.file?.url}`}
                            alt={
                              item?.fields.imageCallout?.fields.description ??
                              ""
                            }
                            height={600}
                            width={600}
                            className="w-full object-cover transition-all"
                          />
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
          <div className="hidden grid-cols-1 md:grid">
            {chosenListItem && (
              <div className="w-100 relative m-auto h-full">
                <Image
                  src={`https:${chosenListItem?.fields.imageCallout?.fields.file?.url}`}
                  alt={
                    chosenListItem?.fields.imageCallout?.fields.description ??
                    ""
                  }
                  height={600}
                  width={600}
                  className="w-full object-cover transition-all"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
