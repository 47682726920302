"use client";

import { revalidate } from "@contentful/utils";
import { faSyncAlt } from "@fortawesome/pro-light-svg-icons/faSyncAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@yardzen/ui";
import { usePathname } from "next/navigation";

export function Admin({ tag }: { tag: string }) {
  const pathname = usePathname();
  return (
    <section className="fixed bottom-4 right-4 flex flex-col items-center justify-center rounded-lg border bg-white p-4 shadow-lg backdrop-blur-md">
      <h2 className="font-display mb-2 text-2xl font-bold">Admin</h2>
      <div className="flex flex-col gap-2">
        <form action={() => revalidate(tag, pathname)}>
          <Button className="flex items-center gap-2">
            <FontAwesomeIcon icon={faSyncAlt} className="h-4 w-4" />
            Revalidate
          </Button>
        </form>
      </div>
    </section>
  );
}
