"use client";

import { revalidate } from "@contentful/utils";
import { faSyncAlt } from "@fortawesome/pro-light-svg-icons/faSyncAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "@yardzen/ui";
import { useFormStatus } from "react-dom";

export function Revalidate() {
  const { pending } = useFormStatus();
  return (
    <div className="flex flex-col gap-2 p-4 pt-28">
      <form
        action={async (formData: FormData) => {
          const tag = formData.get("tag") as string;
          if (tag) {
            await revalidate(tag);
          }
        }}
      >
        <div className="flex gap-2">
          <Input name="tag" placeholder="Enter tag to revalidate" required />
          <Button
            type="submit"
            className="flex items-center gap-2"
            disabled={pending}
          >
            <FontAwesomeIcon
              icon={faSyncAlt}
              className={`h-4 w-4 ${pending ? "animate-spin" : ""}`}
            />
            {pending ? "Revalidating..." : "Revalidate"}
          </Button>
        </div>
      </form>
    </div>
  );
}
