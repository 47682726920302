"use client";

import { TypeTimeline, TypeTimelineItem } from "@contentful/types";
import { notEmpty } from "@yardzen/js-util";
import {
  Badge,
  Carousel,
  CarouselContent,
  CarouselItem,
  cn,
  H1,
  H3,
  P,
} from "@yardzen/ui";
import Image from "next/image";
import React, { useState } from "react";

export function Timeline({
  timeline,
  segmentFlow,
}: {
  timeline: TypeTimeline<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  const { heading, subheading, timelineSegments } = timeline.fields ?? {};

  const [chosenTimeLineIndex, setTimelineIndex] = useState(0);
  const chooseTimeLine = (index: number) => {
    setTimelineIndex(index);
  };

  const TimeLineItemContent = (
    timeLineItem: TypeTimelineItem<"WITHOUT_UNRESOLVABLE_LINKS">,
    i: number,
  ) => {
    const isChoosen = chosenTimeLineIndex === i;
    return (
      <div
        className={cn(
          "w-full transform justify-items-center duration-150 ease-in-out md:w-60 md:pt-8",
          { "md:mx-6 md:scale-[1.2]": isChoosen },
        )}
        onMouseEnter={() => chooseTimeLine(i)}
        onClick={() => chooseTimeLine(i)}
      >
        {timeLineItem.fields.lineSegmentText && (
          <div className="text-center">
            <Badge
              className="bg-brand-tertiary-50 text-small mx-auto mt-2 inline-block text-center font-normal"
              variant="secondary"
              hover={false}
            >
              {timeLineItem.fields.lineSegmentText}
            </Badge>
          </div>
        )}
        {/* Grey arrow */}
        <div className="border-brand-primary-lighter relative mr-1 mt-5 w-full border-t border-solid">
          <div className="bg-brand-primary-lighter border-brand-primary-lighter absolute -mt-1 h-1.5 w-1.5 rounded-full border border-solid"></div>
          <div className="border-brand-primary-lighter absolute right-0 -ml-1 -mt-1 h-1.5 w-1.5 rotate-45 border-r border-t border-solid"></div>
        </div>
        {/* Green dot line */}
        <div className="border-brand-greens-regular mx-auto mb-2 mt-3 h-10 w-px border border-solid">
          <div className="bg-brand-greens-regular border-brand-greens-regular absolute -ml-1.5 -mt-1 h-3 w-3 rounded-full border border-solid"></div>
        </div>
        {timeLineItem?.fields.image && (
          <div className="justify-center rounded-lg border-none !bg-white p-4 no-underline shadow-none">
            <Image
              src={`https:${timeLineItem?.fields.image?.fields.image?.fields.file?.url}`}
              alt={timeLineItem?.fields.image?.fields.description ?? ""}
              height={300}
              width={300}
              className="rounded-lg object-cover"
            />
            <div className="h-52 md:h-44">
              <H3 className="mt-2 text-2xl md:text-xl">
                {timeLineItem?.fields.image?.fields.label ?? ""}
              </H3>
              <div className="mt-2">
                <P className="md:text-sm">
                  {timeLineItem?.fields.image?.fields.description ?? ""}
                </P>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="pb-10 md:pb-16">
      <div className="flex flex-col items-center text-center">
        {heading && (
          <H1 className="text-brand-primary-darker px-4 pt-12 text-4xl md:text-4xl">
            {heading}
          </H1>
        )}
        {subheading && (
          <P className="text-brand-primary-darker w-[90%] pb-10 text-base md:min-w-[37%] md:max-w-[65%]">
            {subheading}
          </P>
        )}
      </div>
      <div className="mb-12 hidden flex-row justify-center gap-8 md:flex">
        {timelineSegments &&
          timelineSegments
            .filter(notEmpty)
            .map((timeLineItem, i) => (
              <React.Fragment key={`d-timeline-${timeLineItem?.sys.id}`}>
                {TimeLineItemContent(timeLineItem, i)}
              </React.Fragment>
            ))}
      </div>
      <div className="md:hidden">
        <Carousel className="px-4">
          <CarouselContent className="pb-6" aria-live="off">
            {timelineSegments &&
              timelineSegments.filter(notEmpty).map((timeLineItem, i) => (
                <CarouselItem
                  key={`m-timeline-${timeLineItem?.sys.id}`}
                  className="basis-9/12"
                >
                  <>{TimeLineItemContent(timeLineItem, i)}</>
                </CarouselItem>
              ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
}
