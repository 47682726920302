import { TypeImage, TypeImageCollection } from "@contentful/types";
import { ImageCollectionCarousel } from "./ImageCollectionCarousel";

import type { JSX } from "react";

function ImageCollection({
  imageCollection,
  variant,
}: {
  imageCollection: TypeImageCollection<"WITHOUT_UNRESOLVABLE_LINKS">;
  variant?: "preview" | "detail" | undefined;
}): JSX.Element {
  const images = imageCollection.fields.images.filter(
    (img): img is TypeImage<"WITHOUT_UNRESOLVABLE_LINKS"> =>
      img !== undefined && img.sys.contentType.sys.id === "image",
  );

  return <ImageCollectionCarousel images={images} variant={variant} />;
}

export { ImageCollection };
