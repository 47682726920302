"use client";

import { TypePackageDetailWithCarousal } from "@contentful/types";
import { cn } from "@yardzen/ui";
import { cva } from "class-variance-authority";
import { ImageCollection } from "./ImageCollection";
import { PackageDetailItem } from "./PackageDetailItem";

export function PackageDetailWithCarousel({
  packageDetailWithCarousel,
  segmentFlow,
  lotSize,
}: {
  packageDetailWithCarousel: TypePackageDetailWithCarousal<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
  lotSize?: string;
}) {
  const packageDetailItem = packageDetailWithCarousel?.fields.package;
  const imageCollection = packageDetailWithCarousel?.fields.carousal;

  if (!packageDetailItem && !imageCollection) {
    return null;
  }

  const variantResolved =
    packageDetailWithCarousel?.fields.variant ?? "preview";

  const variants = cva("", {
    variants: {
      background: {
        detail: "",
        preview: "bg-brand-greens-dark bg-opacity-40",
      },
      gridContainer: {
        detail: "lg:grid-cols-7",
        preview: "lg:grid-cols-2",
      },
      carouselColumn: {
        detail: "col-span-1 lg:col-span-4 order-first lg:order-last",
        preview: "order-first",
      },
      textColumn: {
        detail: "col-span-1 lg:col-span-3",
        preview: "",
      },
    },
  });

  return (
    <div className="mt-6 flex flex-col items-center justify-center px-1 md:mt-5 md:px-8">
      <div
        className={cn(
          "w-full max-w-7xl rounded-lg max-md:max-w-full",
          variants({ background: variantResolved }),
        )}
      >
        <div
          className={cn(
            "grid grid-cols-1 justify-center gap-4",
            variants({ gridContainer: variantResolved }),
          )}
        >
          {imageCollection && (
            <div
              className={variants({
                carouselColumn: variantResolved,
              })}
            >
              <ImageCollection
                imageCollection={imageCollection}
                variant={variantResolved}
              />
            </div>
          )}
          {packageDetailItem && (
            <div className={variants({ textColumn: variantResolved })}>
              <PackageDetailItem
                packageDetailItem={packageDetailItem}
                segmentFlow={segmentFlow}
                variant={variantResolved}
                lotSize={lotSize}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
