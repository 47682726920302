import * as icons from "@fortawesome/pro-light-svg-icons";
import { IconDefinition, IconName } from "@fortawesome/pro-light-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { isIconDefinition } from "@yardzen/next-client-util";
import { forwardRef } from "react";

const iconDefinitions = Object.values(icons).filter(isIconDefinition);

const iconNames = new Set(
  iconDefinitions.map((name: IconDefinition) => name.iconName),
);

export function isIconName(name: string): name is icons.IconName {
  return iconNames.has(name as icons.IconName);
}

const kebabToCamel = (str: string) =>
  str.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase());

// cube -> faCube
// cube-alt -> faCubeAlt
// cube-transparent -> faCubeTransparent
function iconNameToImportName(icon: IconName) {
  return `fa${uppercaseFirst(kebabToCamel(icon))}`;
}

const uppercaseFirst = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

type IconProps = { icon: string } & Omit<FontAwesomeIconProps, "icon">;

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ icon, ...props }, ref) => {
    if (isIconName(icon)) {
      const importName = iconNameToImportName(icon);
      const definition = icons[importName as keyof typeof icons];
      if (isIconDefinition(definition)) {
        return <FontAwesomeIcon icon={definition} ref={ref} {...props} />;
      }
      return (
        <div>
          icon not found: {icon} - {importName}
        </div>
      );
    }
    return <div>icon not found: {icon}</div>;
  },
);

Icon.displayName = "Icon";
