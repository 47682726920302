import { TypePackageDetailItem } from "@contentful/types";
import { PackageTeaserCard } from "@yardzen/ui";
import { cva } from "class-variance-authority";
import { RichText } from "./rich-text/RichText";

export function PackageTeaserItem({
  packageTeaserItem,
  segmentFlow,
  displayDescription,
}: {
  packageTeaserItem: TypePackageDetailItem<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
  displayDescription?: boolean;
}) {
  const description = packageTeaserItem?.fields.description ? (
    <RichText
      content={packageTeaserItem.fields.description}
      className="text-brand-primary-regular font-body mb-6 mt-2 text-left text-sm font-normal"
    />
  ) : null;

  // the package teaser item always uses the Packages Page CTA field, even on detail pages
  const cta = packageTeaserItem?.fields.packagesPageCta;

  const variantResolved =
    packageTeaserItem?.fields.pricePresentation ?? "Next to Header";
  const pricePresentationVariants = cva("", {
    variants: {
      priceContainerClass: {
        "Above CTA": `text-brand-primary-regular mt-2 mb-2 md:mb-0 pt-2 md:pt-8 text-left ${packageTeaserItem?.fields.promoPrice ? "text-lg md:text-base" : "text-lg"} flex flex-1 items-end`,
        "Next to Header":
          "text-brand-primary-regular mt-2 text-left text-base md:text-lg",
      },
    },
  });

  return (
    <div className="flex flex-1 flex-col justify-center gap-2 md:flex-row lg:flex-col">
      <PackageTeaserCard
        segmentFlow={segmentFlow}
        heading={packageTeaserItem?.fields.heading}
        description={description}
        price={packageTeaserItem?.fields.price}
        promoPrice={packageTeaserItem?.fields.promoPrice}
        pricePresentationClass={pricePresentationVariants({
          priceContainerClass: variantResolved,
        })}
        ctaUrl={cta?.fields.url}
        ctaButtonText={cta?.fields.buttonText}
        icon1={packageTeaserItem?.fields.icon1}
        icon2={packageTeaserItem?.fields.icon2}
        displayDescription={displayDescription}
      />
    </div>
  );
}
