"use client";

import { TypeSelectOption } from "@contentful/types";
import { analytics } from "@yardzen/next-segment";
import {
  Button,
  SegmentFlowNames,
  SegmentInputTypes,
  Select,
} from "@yardzen/ui";

export function AddToCartForm({
  packageOptions,
  subFlowName,
}: {
  packageOptions: TypeSelectOption<"WITHOUT_UNRESOLVABLE_LINKS">[];
  /**
   * The name of the sub flow for tracking.
   *
   * This is usually the name of the package that the user is purchasing.
   *
   * @example "Full Yard"
   */
  subFlowName: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      <form
        method="GET"
        action="https://dashboard.yardzen.com/create-account"
        className="flex flex-col gap-2"
        onSubmit={async () => {
          await analytics.trackFormSubmitted({
            flow_name: SegmentFlowNames.PACKAGES,
            form_name: "Lot Size Form",
          });
        }}
      >
        {packageOptions && packageOptions.length > 0 ? (
          <div className="flex flex-col gap-2">
            <label htmlFor="package" className="text-sm font-medium">
              Select options:
            </label>
            <Select
              id="package"
              name="package"
              tracking={{
                flow_name: SegmentFlowNames.PACKAGES,
                form_name: "Lot Size Form",
                field_name: "Package Selection",
                sub_flow_name: subFlowName,
                input_type: SegmentInputTypes.SELECT,
              }}
            >
              {packageOptions.map((option) => (
                <option key={option.fields.value} value={option.fields.value}>
                  {option.fields.label}
                </option>
              ))}
            </Select>
          </div>
        ) : null}
        <Button type="submit">Add to Cart</Button>
      </form>
    </div>
  );
}
