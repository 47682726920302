import { TypeListItem } from "@contentful/types";
import {
  Icon,
  isIconName,
  ToggleCard,
  ListItem as UIListItem,
} from "@yardzen/ui";

export function ListItem({
  listItem,
  variant,
  hideSubHeading,
  className,
  headingClassName,
  subHeadingClassName,
  iconClassName,
  iconWrapperClassName,
  iconSize,
}: {
  listItem: TypeListItem<"WITHOUT_UNRESOLVABLE_LINKS">;
  variant?: "icon-left" | "icon-above";
  hideSubHeading?: boolean;
  className?: string;
  headingClassName?: string;
  subHeadingClassName?: string;
  iconClassName?: string;
  iconWrapperClassName?: string;
  iconSize?: "sm" | "md" | "lg";
}) {
  const icon = listItem?.fields.icon;
  const heading = listItem?.fields.heading ?? "";
  const subheading = listItem?.fields.subheading ?? "";

  return variant === "icon-above" ? (
    icon && isIconName(icon) && (
      <ToggleCard
        className="h-20 border-none !bg-white p-4 no-underline shadow-none"
        disabled={true}
        icons={[<Icon name={icon} className="text-brand-primary-regular" />]}
        title={heading}
      />
    )
  ) : (
    <UIListItem
      iconName={icon}
      heading={heading}
      subheading={!hideSubHeading ? subheading : undefined}
      className={className}
      iconSize={iconSize}
      iconClassName={iconClassName}
      iconWrapperClassName={iconWrapperClassName}
      headingClassName={headingClassName}
      subHeadingClassName={subHeadingClassName}
    />
  );
}
