"use client";

import { TypeGenericSection } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LandscapeSolutionsAnythingElseIcon,
  LandscapeSolutionsDrainageIcon,
  LandscapeSolutionsKidsIcon,
  LandscapeSolutionsNativePlantsIcon,
  LandscapeSolutionsNoiseIcon,
  LandscapeSolutionsPetsIcon,
  LandscapeSolutionsPrivacyIcon,
  LandscapeSolutionsRetainingWallIcon,
  LandscapeSolutionsSeasonalUseIcon,
  LandscapeSolutionsShadeIcon,
  LandscapeSolutionsSlopeIcon,
  LandscapeSolutionsWaterReductionIcon,
  LivingAreasAnythingElseIcon,
  LivingAreasColdPlungeIcon,
  LivingAreasDeckIcon,
  LivingAreasDining,
  LivingAreasEdibleGarden,
  LivingAreasFirepitIcon,
  LivingAreasKitchenIcon,
  LivingAreasLoungeIcon,
  LivingAreasPatioIcon,
  LivingAreasPlaySpaceIcon,
  LivingAreasSaunaIcon,
  LivingAreasSwimmingPoolIcon,
} from "@yardzen/components/icons";
import { Button, H1, H3 } from "@yardzen/ui";
import { useRouter } from "next/navigation";
import * as React from "react";
import { CardsSection } from "./CardsSection";

export const WhatCanBeIncluded: React.FC<{
  whatCanBeIncluded: TypeGenericSection<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}> = ({ whatCanBeIncluded, segmentFlow }) => {
  const livingAreaCards = [
    {
      icon: LivingAreasPatioIcon,
      title: "Patio",
    },
    {
      icon: LivingAreasDeckIcon,
      title: "Pergola",
    },
    {
      icon: LivingAreasEdibleGarden,
      title: "Edible Garden",
    },
    {
      icon: LivingAreasSaunaIcon,
      title: "Sauna",
    },
    {
      icon: LivingAreasLoungeIcon,
      title: "Deck",
    },
    {
      icon: LivingAreasColdPlungeIcon,
      title: "Cold Plunge",
    },
    {
      icon: LivingAreasFirepitIcon,
      title: "Firepit",
    },
    {
      icon: LivingAreasDining,
      title: "Dining",
    },
    {
      icon: LivingAreasPlaySpaceIcon,
      title: "Play Space",
    },
    {
      icon: LivingAreasKitchenIcon,
      title: "Kitchen",
    },
    {
      icon: LivingAreasSwimmingPoolIcon,
      title: "Swimming Pool",
    },
    {
      icon: LivingAreasAnythingElseIcon,
      title: "And anything else!",
    },
  ];

  const landscapeSolutions = [
    {
      icon: LandscapeSolutionsPrivacyIcon,
      title: "Privacy",
    },
    {
      icon: LandscapeSolutionsNativePlantsIcon,
      title: "Native Plants",
    },
    {
      icon: LandscapeSolutionsSlopeIcon,
      title: "Slope",
    },
    {
      icon: LandscapeSolutionsWaterReductionIcon,
      title: "Water Reduction",
    },
    {
      icon: LandscapeSolutionsDrainageIcon,
      title: "Drainage",
    },
    {
      icon: LandscapeSolutionsSeasonalUseIcon,
      title: "Seasonal Use",
    },
    {
      icon: LandscapeSolutionsShadeIcon,
      title: "Shade",
    },
    {
      icon: LandscapeSolutionsPetsIcon,
      title: "Pets",
    },
    {
      icon: LandscapeSolutionsNoiseIcon,
      title: "Noise",
    },
    {
      icon: LandscapeSolutionsKidsIcon,
      title: "Kids",
    },
    {
      icon: LandscapeSolutionsRetainingWallIcon,
      title: "Retaining Wall",
    },
    {
      icon: LandscapeSolutionsAnythingElseIcon,
      title: "And anything else!",
    },
  ];

  const cta = whatCanBeIncluded.fields.CTAs?.find(
    (cta) => cta?.fields.variant === "default",
  );

  const ctaProps = cta
    ? {
        endIcon: <FontAwesomeIcon icon={faArrowRight} />,
      }
    : {};

  const router = useRouter();

  return (
    <div className="flex flex-col px-8 pb-8 md:pb-16">
      <H1 className="text-center text-5xl leading-tight max-md:max-w-full max-md:text-4xl">
        {whatCanBeIncluded.fields.title || "Your yard, your way."}
      </H1>
      <H3 className="text-brand-brown-black p-2 text-center text-lg leading-tight max-md:max-w-full">
        {whatCanBeIncluded.fields.subtitle ||
          "Our world-class design team can help with any challenge and project type."}
      </H3>
      <div className="mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col justify-center gap-8 md:flex-row">
          <CardsSection
            title="Living Areas"
            cards={livingAreaCards}
            url={cta?.fields.url}
          />
          <CardsSection
            title="Landscape Solutions"
            cards={landscapeSolutions}
            url={cta?.fields.url}
          />
        </div>
        <div className="flex justify-center">
          <Button
            className="mt-8 md:mt-16"
            {...ctaProps}
            onClick={() => {
              cta?.fields?.url && router.push(cta?.fields.url);
            }}
            tracking={{
              button_content: cta?.fields.buttonText || "",
              flow_name: segmentFlow,
              button_name: "What can be included CTA",
            }}
          >
            {cta?.fields.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
