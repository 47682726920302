"use client";

import { cn } from "@yardzen/next-client-util";
import { Button } from "@yardzen/ui";
import Image from "next/image";
import { ReactNode, forwardRef, useState } from "react";

import { TypeIncludes } from "@contentful/types";
import { Icon } from "./icon/Icon";

type Item = {
  title: string;
  description: string;
  src: string;
  icon?: string;
};

export function DesignIncludes({
  includes,
}: {
  includes: TypeIncludes<"WITHOUT_UNRESOLVABLE_LINKS">;
}) {
  const [activeTab, setActiveTab] = useState(0);
  const features = includes.fields.features.reduce<Item[]>(
    (accumulatedItems, feature) => {
      if (
        !feature ||
        !feature.fields.label ||
        !feature.fields.description ||
        !feature.fields.image?.fields.file?.url
      ) {
        return accumulatedItems;
      }
      return [
        ...accumulatedItems,
        {
          title: feature.fields.label,
          description: feature.fields.description,
          src: `https:${feature.fields.image.fields.file?.url}`,
          icon: feature.fields.icon,
        },
      ];
    },
    [],
  );
  if (features.length === 0) return null;
  return (
    <div className="flex flex-col gap-2 py-5 md:gap-7 md:py-10">
      <div className="flex justify-between gap-2">
        <h3 className="font-display text-3xl">{includes.fields.title}</h3>
        <Button className="hidden md:block">View a Sample Project</Button>
      </div>
      <dl
        role="tablist"
        className="flex flex-col place-items-start gap-2 md:grid md:grid-cols-[1fr_2fr] md:gap-7"
      >
        {features.map((item, index) => (
          <ItemDisplay
            key={index}
            item={item}
            index={index}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </dl>
    </div>
  );
}

interface ItemDisplayProps {
  item: Item;
  index: number;
  activeTab: number;
  setActiveTab: (index: number) => void;
}

const ItemDisplay: React.FC<ItemDisplayProps> = ({
  item,
  index,
  activeTab,
  setActiveTab,
}) => {
  const isActive = activeTab === index;
  return (
    <>
      <dt
        className={cn("w-full rounded-lg p-4", {
          "bg-white": isActive,
        })}
      >
        <Tab onClick={() => setActiveTab(index)} active={isActive}>
          {item.icon && <Icon icon={item.icon} className="h-5 w-5" />}
          {item.title}
        </Tab>
      </dt>
      <dd
        // images have 1.84 aspect ratio
        className={cn(
          "relative w-full overflow-hidden",
          {
            "h-[calc(100vw/1.84)] opacity-100": isActive,
            "h-0 opacity-0": !isActive,
          },
          "transition-all duration-300 ease-in-out",
          "md:col-start-2 md:row-start-1 md:row-end-7 md:h-[calc(66.67vw/1.84)] lg:h-[calc(700px/1.84)]",
        )}
      >
        <Image
          src={item.src}
          fill
          alt={item.description}
          sizes="(max-width: 900px) 100vw, (max-width: 1200px) 66.67vw, 762px"
          className="w-full rounded-lg object-cover"
        />
      </dd>
    </>
  );
};

const Tab = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    onClick: () => void;
    active: boolean;
    className?: string;
  }
>(({ children, active, ...props }, ref) => (
  <button
    ref={ref}
    role="tab"
    {...props}
    className={cn(
      "font-display flex items-center gap-2 text-left text-2xl",
      active && "bg-white/50",
    )}
    onMouseEnter={props.onClick}
  >
    {children}
  </button>
));
Tab.displayName = "Tab";
