import { TypeImage } from "@contentful/types";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  cn,
} from "@yardzen/ui";
import { cva } from "class-variance-authority";
import Image from "next/image";

export function ImageCollectionCarousel({
  images,
  variant,
}: {
  images: TypeImage<"WITHOUT_UNRESOLVABLE_LINKS">[];
  variant?: "preview" | "detail" | undefined;
}) {
  const variantResolved = variant ?? "preview";
  const variants = cva("", {
    variants: {
      containerClass: {
        detail: "rounded-lg",
        preview: "md:rounded-l-lg",
      },
      imageClass: {
        detail: "rounded-lg",
        preview: "md:rounded-l-lg",
      },
    },
  });
  return (
    <Carousel
      className="h-full w-full"
      opts={{
        loop: true,
      }}
    >
      <CarouselContent className="">
        {images?.map((image, index) => {
          const imageUrl = image?.fields?.image?.fields?.file?.url;
          const alt = image?.fields?.image?.fields?.description;
          if (!imageUrl) {
            return null;
          }
          return (
            <CarouselItem
              key={`{image?.sys.id}-${index}`}
              className={cn("", variants({ containerClass: variantResolved }))}
            >
              <Card className="border-none bg-opacity-0 p-0 shadow-none">
                <Image
                  src={`https:${imageUrl}`}
                  alt={alt ?? ""}
                  height={700}
                  width={700}
                  className={cn(
                    "aspect-square h-full w-full object-cover",
                    variants({ imageClass: variantResolved }),
                  )}
                  loading="lazy"
                />
              </Card>
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselPrevious
        className="bg-brand-greens-light left-1 ml-2 md:left-2"
        aria-label="Previous image"
        variant="zest"
        icon={faChevronLeft}
      />
      <CarouselNext
        className="bg-brand-greens-light right-1 mr-2 md:mr-3"
        aria-label="Next image"
        variant="zest"
        icon={faChevronRight}
      />
    </Carousel>
  );
}
