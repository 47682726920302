"use client";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { TypePackageDetailItem } from "@contentful/types";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SegmentFlows, SegmentForms } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import {
  B,
  Button,
  cn,
  Eyebrow,
  H1,
  H2,
  Icon,
  isIconName,
  Link,
  P,
  Select,
  SelectItem,
} from "@yardzen/ui";
import { cva } from "class-variance-authority";
import Cookies from "js-cookie";
import { useSearchParams } from "next/navigation";
import { ChangeEvent, useState } from "react";
import { ListItemCollection } from "./ListItemCollection";

export function PackageDetailItem({
  packageDetailItem,
  segmentFlow,
  variant,
  lotSize,
}: {
  packageDetailItem: TypePackageDetailItem<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
  variant?: "detail" | "preview" | undefined;
  lotSize?: string;
}) {
  const {
    primaryEyebrow,
    listItems,
    heading,
    fullPackageName,
    price,
    promoPrice,
    numberOfInstallments,
    pricePerInstallment,
    description,
    icon1,
    icon2,
    modificationOptions,
    cta,
    packagesPageCta,
  } = packageDetailItem?.fields || {};

  const initialModificationOption = lotSize
    ? modificationOptions?.find(
        (option) => option?.fields.internalValue === lotSize,
      )
    : modificationOptions?.[0];

  const [currentModificationOption, setCurrentModificationOption] = useState(
    initialModificationOption,
  );
  const searchParams = useSearchParams();
  const isRecommended = searchParams?.get("is-recommended") === "true";

  const chooseModificationOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const chosenOption = modificationOptions?.find(
      (option) => option?.fields.internalValue === e?.target?.value,
    );
    // if for some reason the select value wasn't valid and the chosen option wasn't found, just ignore
    if (chosenOption) {
      setCurrentModificationOption(chosenOption);
    }
    analytics.trackFormFieldFilled({
      form_name: SegmentForms.CHECKOUT_PACKAGE_DETAILS,
      flow_name: SegmentFlows.CHECKOUT,
      field_name: `Modification Option: ${chosenOption?.fields.heading}`,
      text_value: chosenOption?.fields.heading,
    });
  };

  const variantResolved = variant ?? "preview";
  const displayDescription = variantResolved === "preview";

  // if this Package Detail Item has modification options, we need to utilize that for the price,
  // installments, installment price, CTA button, etc.
  const ctaToUse = currentModificationOption
    ? currentModificationOption?.fields.cta
    : variantResolved === "detail"
      ? cta
      : packagesPageCta;
  // Add yzsa parameter to url like wordpress was doing.
  const ctaUrl = new URL(ctaToUse?.fields.url ?? "");

  const yzsaCookie = Cookies.get("yzsa");

  if (
    variantResolved === "detail" &&
    yzsaCookie &&
    ctaUrl &&
    !ctaUrl.searchParams.has("yzsa")
  ) {
    ctaUrl.searchParams.set("yzsa", encodeURIComponent(yzsaCookie));
  }
  const headingToUse =
    variantResolved === "detail" && fullPackageName ? fullPackageName : heading;

  const priceToUse = currentModificationOption
    ? currentModificationOption.fields.price
    : price;
  const promoPriceToUse = currentModificationOption
    ? currentModificationOption.fields.promoPrice
    : promoPrice;
  const installmentsToUse = currentModificationOption
    ? currentModificationOption.fields.numberOfInstallments
    : numberOfInstallments;
  const installmentPriceToUse = currentModificationOption
    ? currentModificationOption.fields.pricePerInstallment
    : pricePerInstallment;

  const pricePresentationVariant =
    variantResolved === "detail"
      ? "Below Header"
      : (packageDetailItem?.fields.pricePresentation ?? "Next to Header");
  const pricePresentationVariants = cva("", {
    variants: {
      headerContainer: {
        "Above CTA": "",
        "Next to Header":
          "flex flex-col lg:flex-row justify-between md:items-center",
        "Below Header": "flex flex-col",
      },
      priceContainer: {
        "Above CTA":
          "text-brand-primary-darker font-display mt-2 font-normal mb-2 md:mb-0 pt-2 md:pt-8 text-left text-base md:text-lg flex flex-1 items-end",
        "Next to Header":
          "text-brand-primary-darker font-display mt-2 font-normal text-left text-base text-lg md:pl-2",
        "Below Header":
          "text-brand-secondary-dark font-display text-left text-4xl md:text-5xl font-normal flex flex-row flex-wrap items-end",
      },
    },
  });

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const layoutVariants = cva("", {
    variants: {
      headingText: {
        detail: "font-display text-left text-5xl lg:text-6xl font-normal",
        preview: "font-display text-left text-3xl font-normal",
      },
    },
  });

  const REVIEW_BASE_URL = process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL;
  const yardzenReviewInfo = {
    rating: "4.8",
    reviewCount: "16,440",
  };

  const affirmLogoIconName = "affirm-logo";

  return (
    <div className="flex flex-1 flex-col justify-center gap-2 md:flex-row lg:flex-col">
      <div className="w-full bg-opacity-0 px-3 pb-3 md:pt-3">
        <div className="lg:p-4">
          <div className="flex flex-row md:flex-col">
            <div className="flex flex-col">
              {variantResolved !== "detail" && (icon1 || icon2) && (
                <div className="mr-3 flex shrink-0 flex-row items-start gap-2 md:mb-4 md:items-center">
                  {icon1 && isIconName(icon1) && (
                    <div className="bg-brand-moss-greens-10 flex w-fit shrink-0 items-center rounded-full p-2">
                      <Icon
                        name={icon1}
                        className="text-brand-primary-regular h-[22px] w-[22px]"
                      />
                    </div>
                  )}
                  {icon2 && isIconName(icon2) && (
                    <div className="bg-brand-greens-light relative -left-4 -mr-3 flex w-fit shrink-0 items-center rounded-full p-2">
                      <Icon
                        name={icon2}
                        className="text-brand-primary-regular h-[24px] w-[24px]"
                      />
                    </div>
                  )}
                </div>
              )}
              {primaryEyebrow && <Eyebrow text={primaryEyebrow} />}
              {isRecommended && (
                <B className="text-system-grayscale-50">
                  Based on your answers we recommend:
                </B>
              )}
              <div
                className={pricePresentationVariants({
                  headerContainer: pricePresentationVariant,
                })}
              >
                {headingToUse && variantResolved === "detail" && (
                  <H1
                    className={layoutVariants({
                      headingText: variantResolved,
                    })}
                  >
                    {headingToUse}
                  </H1>
                )}
                {headingToUse && variantResolved !== "detail" && (
                  <H2
                    className={cn(
                      layoutVariants({
                        headingText: variantResolved,
                      }),
                    )}
                  >
                    {headingToUse}
                  </H2>
                )}

                {priceToUse &&
                  pricePresentationVariant === "Next to Header" && (
                    <div
                      className={pricePresentationVariants({
                        priceContainer: pricePresentationVariant,
                      })}
                    >
                      {promoPriceToUse ? (
                        <div className="flex w-full basis-1 flex-row items-center">
                          <p className="text-system-grayscale-50 ">
                            <span className="pr-2 line-through">
                              ${priceToUse}
                            </span>
                          </p>
                          <div>${Number(promoPriceToUse)}</div>
                        </div>
                      ) : (
                        <div>
                          <b>${priceToUse}</b>
                        </div>
                      )}
                    </div>
                  )}

                {priceToUse && pricePresentationVariant === "Below Header" && (
                  <div
                    className={pricePresentationVariants({
                      priceContainer: pricePresentationVariant,
                    })}
                  >
                    {promoPriceToUse ? (
                      <div className="flex w-full basis-1 flex-row items-center gap-4">
                        <div className="text-system-grayscale-50 line-through">
                          ${priceToUse}
                        </div>
                        <div>${Number(promoPriceToUse)}</div>
                      </div>
                    ) : (
                      <div>${priceToUse}</div>
                    )}

                    <div className="mx-4 mb-1 flex flex-row items-center">
                      <Link
                        href={`${REVIEW_BASE_URL}/reviews`}
                        className="flex items-center justify-center gap-1.5 self-start"
                        tracking={{
                          button_content: `${yardzenReviewInfo.reviewCount} reviews`,
                          flow_name: segmentFlow,
                          button_name: "Review badge",
                        }}
                      >
                        <div className="flex h-full items-center">
                          {[...Array(5)].map((e, i) => (
                            <FontAwesomeIcon
                              key={`star-${i}`}
                              icon={faStar}
                              className="text-brand-primary-light h-4 w-4 px-0.5"
                              aria-hidden="true"
                              role="presentation"
                              style={{
                                clipPath: `${i === 4 ? "inset(0 30% 0 0)" : ""}`,
                              }}
                            />
                          ))}
                        </div>
                        <P
                          className="text-base opacity-50"
                          variant="subdued"
                          noSpacing
                        >
                          {yardzenReviewInfo.reviewCount} Reviews
                        </P>
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              {installmentsToUse !== undefined && installmentsToUse > 0 && (
                <div className="font-body text-system-grayscale-100 mb-2 ml-1 mt-4 flex flex-row items-center text-sm font-normal md:mb-4">
                  <span className="opacity-50">
                    Pay {installmentsToUse} interest-free payments of{" "}
                    {formatter.format(installmentPriceToUse ?? 0)} with{" "}
                  </span>
                  {isIconName(affirmLogoIconName) && (
                    <Icon
                      name={affirmLogoIconName}
                      className="object-fit mb-1 ml-1 h-auto w-[40px] pb-0.5 opacity-80"
                    />
                  )}
                </div>
              )}

              {displayDescription && description && (
                <div className="text-brand-primary-dark font-body mb-2 mt-2 text-left text-sm font-normal md:mb-4 md:mt-8 md:text-lg">
                  {documentToReactComponents(description)}
                </div>
              )}
              {listItems && (
                <ListItemCollection
                  listItemCollection={listItems}
                  hideCollectionHeader
                  listItemsContainerClassName={`mb-2 flex flex-row ${variantResolved === "detail" ? "items-start" : "items-center"} gap-2`}
                />
              )}
              {modificationOptions && (
                <Select
                  defaultValue={lotSize}
                  onChange={chooseModificationOption}
                  className="mb-6 mt-4 h-11 !w-full max-w-full rounded-md border-0 bg-white px-4 text-base font-normal shadow-lg shadow-black/5 focus:outline-none focus:ring-0 lg:mb-4 lg:mt-0"
                >
                  {modificationOptions.map((modificationOption) => (
                    <SelectItem
                      className="hover:bg-brand-beige-lighter h-12 bg-white px-4"
                      key={modificationOption?.fields.internalValue}
                      value={modificationOption?.fields.internalValue}
                      tracking={{
                        field_name: `Modification Option: ${modificationOption?.fields.heading}`,
                        form_name: `Package Detail With Carousel: ${packageDetailItem?.fields.heading}`,
                        flow_name: segmentFlow,
                        text_value: modificationOption?.fields.heading,
                      }}
                    >
                      {modificationOption?.fields.heading}
                    </SelectItem>
                  ))}
                </Select>
              )}

              {priceToUse &&
                pricePresentationVariant === "Above CTA" &&
                variantResolved !== "detail" && (
                  <div
                    className={pricePresentationVariants({
                      priceContainer: pricePresentationVariant,
                    })}
                  >
                    <b>${priceToUse}</b>
                  </div>
                )}
            </div>
          </div>
        </div>
        {ctaToUse?.fields.buttonText && (
          <div className="flex items-end p-6 px-0 pb-2 pt-0 md:pt-2">
            <Button
              className="mx-2 w-full"
              asChild
              tracking={{
                button_name: segmentFlow,
                button_content: ctaToUse?.fields.buttonText,
                flow_name: SegmentFlows.CHECKOUT,
              }}
              onClick={async () => {
                const eventData = {
                  form_name: SegmentForms.CHECKOUT_PACKAGE_DETAILS,
                  flow_name: SegmentFlows.CHECKOUT,
                  package: packageDetailItem?.fields.heading,
                };
                await analytics.trackFormSubmitted(eventData);
              }}
            >
              <Link
                className="hidden whitespace-nowrap font-medium max-[429px]:py-3 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
                href={ctaUrl.toString()}
              >
                {ctaToUse?.fields.buttonText}
              </Link>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
