"use client";
import { cn } from "@yardzen/next-client-util";
import { Button, P } from "@yardzen/ui";
import Image from "next/image";
import { useState } from "react";

// TODO: move this out of products/[slug]/components

/**
 * A carousel of images.
 * @docs https://www.w3.org/WAI/ARIA/apg/patterns/carousel/
 * @docs https://www.w3.org/WAI/tutorials/carousels
 */
export function ImageCarousel({
  images,
  className,
}: {
  images: { src: string; alt: string; width: number; height: number }[];
  className?: string;
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isLastImage = currentImageIndex === images.length - 1;
  const isFirstImage = currentImageIndex === 0;

  const showNext = () => {
    const nextIndex = currentImageIndex + 1;
    if (isLastImage) {
      return;
    }
    setCurrentImageIndex(nextIndex);
  };

  const showPrevious = () => {
    const prevIndex = currentImageIndex - 1;
    if (isFirstImage) {
      return;
    }
    setCurrentImageIndex(prevIndex);
  };

  return (
    <section
      aria-roledescription="carousel"
      aria-label="Gallery of designs"
      className={cn("flex flex-col gap-10", className)}
    >
      <div className="grid gap-4">
        <div className="relative col-span-12 min-h-[300px] transition-all sm:min-h-[450px] md:min-h-[600px]">
          <Image
            key={currentImageIndex}
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            fill
            priority
            sizes="(max-width: 900px) 100vw, 66vw"
            className="object-cover object-center"
          />
          <LeftCarouselButton onClick={showPrevious} />
          <RightCarouselButton onClick={showNext} />
        </div>
        <div
          className="col-span-12 flex flex-row justify-center gap-2 sm:grid sm:min-h-[100px] sm:grid-cols-6"
          role="tablist"
        >
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              image={image}
              index={index}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

interface ThumbnailProps {
  image: { src: string; alt: string; width?: number; height?: number };
  index: number;
  currentImageIndex: number;
  setCurrentImageIndex: (index: number) => void;
}

function Thumbnail({
  image,
  index,
  currentImageIndex,
  setCurrentImageIndex,
}: ThumbnailProps) {
  return (
    <>
      <button
        key={image.src + "button"}
        className={cn("relative hidden border-2 border-white sm:block", {
          "border-primary": currentImageIndex === index,
        })}
        role="tab"
        aria-selected={currentImageIndex === index}
        aria-controls={`carousel-item-${index}`}
        id={`carousel-tab-${index}`}
        onClick={() => setCurrentImageIndex(index)}
      >
        <Image
          src={image.src}
          fill
          className="object-cover object-center"
          sizes="(max-width: 900px) 16vw, 9vw"
          alt={`Thumbnail of ${image.alt}`}
        />
      </button>
      <input
        key={image.src + "input"}
        role="tab"
        type="radio"
        aria-selected={currentImageIndex === index}
        aria-controls={`carousel-item-${index}`}
        value={index}
        className="sm:hidden"
        checked={currentImageIndex === index}
        onChange={() => setCurrentImageIndex(index)}
      />
    </>
  );
}

function CarouselButton({
  children,
  className,
  onClick,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      variant="ghost"
      className={cn(
        "absolute top-[calc(50%-16px)] size-8 rounded-[47px] bg-white/50 p-0 hover:bg-white/90 focus:bg-white/90 focus:shadow-md",
        className,
      )}
      onClick={onClick}
      aria-controls="carousel-content"
      {...props}
    >
      {children}
    </Button>
  );
}

function LeftCarouselButton({
  className,
  onClick,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <CarouselButton
      onClick={onClick}
      className={cn("left-2", className)}
      aria-label="Previous image"
      {...props}
    >
      <svg
        width="33"
        height="34"
        viewBox="0 0 33 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 2608169">
          <rect
            x="0.148438"
            y="0.802811"
            width="31.978"
            height="33.1679"
            rx="15.989"
            fill="white"
            fillOpacity="0.5"
          />
          <path
            id="Vector"
            d="M18.447 23.773C18.811 24.0912 19.3637 24.054 19.6819 23.6903C19.9998 23.3263 19.9626 22.7736 19.5989 22.4554L13.3518 16.9894L19.5993 11.5231C19.9633 11.2049 20.0001 10.6522 19.6823 10.2885C19.3641 9.92445 18.8113 9.88728 18.4473 10.2055L11.4474 16.3306C11.2574 16.4966 11.1484 16.7369 11.1484 16.9894C11.1484 17.2419 11.2574 17.4819 11.4474 17.6482L18.447 23.773Z"
            fill="black"
          />
        </g>
      </svg>
      <P className="sr-only">Previous Image</P>
    </CarouselButton>
  );
}

function RightCarouselButton({
  className,
  onClick,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <CarouselButton
      className="right-2"
      aria-label="Next image"
      onClick={onClick}
      {...props}
    >
      <svg
        width="33"
        height="34"
        viewBox="0 0 33 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 2608170">
          <rect
            x="0.873047"
            y="0.802811"
            width="31.978"
            height="33.1679"
            rx="15.989"
            fill="white"
            fillOpacity="0.5"
          />
          <path
            id="Vector"
            d="M15.3245 9.2047C14.9605 8.88651 14.4077 8.92368 14.0896 9.28737C13.7717 9.65139 13.8088 10.2041 14.1725 10.5223L20.4197 15.9883L14.1722 21.4546C13.8082 21.7728 13.7714 22.3255 14.0892 22.6892C14.4074 23.0532 14.9602 23.0904 15.3242 22.7722L22.3241 16.6471C22.5141 16.4811 22.623 16.2408 22.623 15.9883C22.623 15.7358 22.5141 15.4958 22.3241 15.3295L15.3245 9.2047Z"
            fill="black"
          />
        </g>
      </svg>
      <P className="sr-only">Next Image</P>
    </CarouselButton>
  );
}
