"use client";

import { TypePackageDetailBanner } from "@contentful/types";
import { SegmentFlows, SegmentForms } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import { Button, Link, P, Select, SelectItem } from "@yardzen/ui";
import Cookies from "js-cookie";
import { throttle } from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";

export function PackageDetailBanner({
  packageDetailBanner,
  segmentFlow,
  lotSize,
}: {
  packageDetailBanner: TypePackageDetailBanner<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
  lotSize?: string;
}) {
  const { modificationOptions } =
    packageDetailBanner?.fields.package?.fields || {};

  const [currentModificationOption, setCurrentModificationOption] = useState(
    modificationOptions ? modificationOptions[0] : null,
  );
  const [active, setActive] = useState(false);
  const bannerOffsetRef = useRef(0);

  const chooseModificationOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const chosenOption = modificationOptions?.find(
      (option) => option?.fields.internalValue === e?.target?.value,
    );
    // if for some reason the select value wasn't valid and the chosen option wasn't found, just ignore
    if (chosenOption) {
      setCurrentModificationOption(chosenOption);
    }
    analytics.trackFormFieldFilled({
      form_name: SegmentForms.CHECKOUT_PACKAGE_DETAILS,
      flow_name: SegmentFlows.CHECKOUT,
      field_name: `Modification Option: ${chosenOption?.fields.heading}`,
      text_value: chosenOption?.fields.heading,
    });
  };

  useEffect(() => {
    // Figure out the trigger point height from top.
    const navElement = document.querySelector("#sub-nav-bar-trigger");
    let currentNavHeight = 0;

    if (navElement !== null && navElement instanceof HTMLElement) {
      const bound = navElement.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      currentNavHeight = scrollTop + bound.top;
    }

    // Throttle the scroll events.
    const scrollHandler = throttle((navHeight: number) => {
      // If user scroll position is past the trigger point show nav
      if (window.scrollY < navHeight) {
        setActive(false);
      } else {
        setActive(true);
      }
    }, 250);

    window.addEventListener(
      "scroll",
      scrollHandler.bind(null, currentNavHeight),
    );
    return () => {
      window.removeEventListener(
        "scroll",
        scrollHandler.bind(null, currentNavHeight),
      );
    };
  }, [setActive]);

  useEffect(() => {
    //This checks for the presence of the promo banner while the user is scrolling
    //since the announcement banner loads at different times
    let lastScrollY = window.scrollY;

    const scrollHandler = throttle(() => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY || currentScrollY < lastScrollY) {
        const promoBannerElement = document.querySelector("#yz-banner");
        if (promoBannerElement) {
          bannerOffsetRef.current = promoBannerElement.clientHeight;
        }
      }

      lastScrollY = currentScrollY;
    }, 250);

    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  // Add yzsa parameter to url like wordpress was doing.
  const ctaUrl = new URL(
    currentModificationOption?.fields.cta?.fields.url ??
      packageDetailBanner.fields.package?.fields.cta?.fields.url ??
      "",
  );

  const yzsaCookie = Cookies.get("yzsa");

  if (ctaUrl && yzsaCookie && !ctaUrl.searchParams.has("yzsa")) {
    ctaUrl.searchParams.set("yzsa", encodeURIComponent(yzsaCookie));
  }

  return (
    <div id="sub-nav-bar-trigger">
      <nav
        id="sub-nav-bar"
        style={{ top: `${bannerOffsetRef.current + 80}px` }}
        className={`bg-system-grayscale-00 fixed left-0 right-0 z-50 mx-4 mt-3 justify-center rounded-lg bg-opacity-80 shadow-md backdrop-blur-[64px] md:mt-2 ${!active ? "hidden" : ""}`}
      >
        <div className="mx-auto flex flex-col justify-between px-6 py-2 md:flex-row md:py-4">
          <div className="hidden items-center md:flex">
            <P className="text-xl">
              {packageDetailBanner.fields.package?.fields.heading}
            </P>
          </div>

          <div className="flex items-center justify-end gap-2 max-sm:flex-col md:justify-center">
            {modificationOptions && (
              <Select
                defaultValue={lotSize}
                wrapperClassName="shrink-0"
                onChange={chooseModificationOption}
                className="border-system-grayscale-20 !w-full max-w-full cursor-pointer rounded-lg border bg-white pr-8 text-base font-normal focus:outline-none focus:ring-0"
              >
                {modificationOptions.map((modificationOption) => (
                  <SelectItem
                    className="hover:bg-brand-beige-lighter min-h-6 bg-white"
                    key={modificationOption?.fields.internalValue}
                    value={modificationOption?.fields.internalValue}
                    tracking={{
                      field_name: `Modification Option: ${modificationOption?.fields.heading}`,
                      form_name: `PackageDetailBanner Select`,
                      flow_name: SegmentFlows.CHECKOUT,
                      text_value: modificationOption?.fields.heading,
                    }}
                  >
                    {`${modificationOption?.fields.heading} - $${modificationOption?.fields.price}`}
                  </SelectItem>
                ))}
              </Select>
            )}
            <Button
              className="mx-2 w-full"
              asChild
              tracking={{
                button_content: `PackageDetailBanner: ${packageDetailBanner.fields.ctaButtonText}`,
                flow_name: SegmentFlows.CHECKOUT,
                button_name: segmentFlow,
              }}
              onClick={async () => {
                const eventData = {
                  form_name: SegmentForms.CHECKOUT_PACKAGE_DETAILS,
                  flow_name: SegmentFlows.CHECKOUT,
                  package: packageDetailBanner.fields.package?.fields.heading,
                };
                await analytics.trackFormSubmitted(eventData);
              }}
            >
              <Link
                className="m-0 whitespace-nowrap font-medium max-[429px]:py-3 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
                href={ctaUrl.toString()}
              >
                {packageDetailBanner.fields.ctaButtonText}
              </Link>
            </Button>
          </div>
        </div>
      </nav>
    </div>
  );
}
