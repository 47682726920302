import { customImageLoader, SegmentFlows } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import { Badge, Link, SegmentClickTypes, ToggleCard } from "@yardzen/ui";
import Image from "next/image";
import { useRouter } from "next/navigation";

interface Card {
  icon: any;
  title: string;
}

interface CardsSectionProps {
  title: string;
  cards: Card[];
  url?: string;
}

export const CardsSection = ({ title, cards, url }: CardsSectionProps) => {
  const router = useRouter();

  return (
    <div className="flex flex-col items-center">
      <Badge
        className="bg-brand-moss-greens-10 my-4 w-fit bg-opacity-50 text-center text-base font-normal"
        variant="secondary"
        hover={false}
      >
        {title}
      </Badge>
      <div className="grid grid-cols-3 gap-4 max-md:grid-cols-2">
        {cards.map((card, index) => (
          <Link
            variant="nav"
            key={card.title}
            className={
              index === cards.length - 1 && cards.length % 2 === 1
                ? "col-span-2 md:col-span-1"
                : ""
            }
            onClick={() => {
              if (!url) return;
              analytics.trackClicked({
                button_content: card.title,
                flow_name: SegmentFlows.HOME,
                button_name: `What can be included - ${card.title}`,
                click_type: SegmentClickTypes.HYPERLINK,
              });
              router.push(url);
            }}
          >
            <ToggleCard
              className="h-20 border-none !bg-white p-4 no-underline shadow-none"
              disabled={true}
              icons={[
                <Image
                  src={card.icon}
                  alt={`${card.title} icon`}
                  width={28}
                  height={28}
                  key={card.title}
                  className="h-7 w-7 object-contain"
                  loading="lazy"
                  loader={customImageLoader}
                />,
              ]}
              title={card.title}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
