"use client";

import { TypeHeroSkeleton, TypeImageSkeleton } from "@contentful/types";
import { customImageLoader } from "@yardzen/next-client-util";
import { Button, H3, P } from "@yardzen/ui";
import { Entry } from "contentful";
import Image from "next/image";
import { useRouter } from "next/navigation";

export function ValuesSection({
  section,
  segmentFlow,
}: {
  section: Entry<TypeHeroSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
  segmentFlow: string;
}) {
  const router = useRouter();

  const description = section?.fields?.description;
  const heading = section?.fields?.subtitle;
  const images = section?.fields?.images;
  const cta = section?.fields?.cta;

  return (
    <div className="bg-brand-primary-darker/70 relative py-5 sm:py-16">
      <div className="text-brand-beige-regular z-40 mx-auto flex h-full w-full max-w-7xl flex-col px-6 py-10">
        <div className="flex flex-col justify-between gap-8 pb-10 sm:flex-row">
          <P className="font-display w-full text-4xl leading-[120%] sm:w-1/2">
            {heading}
          </P>
          <P className="!mt-0 w-full text-lg leading-[120%] sm:w-1/2">
            {description}
          </P>
        </div>

        <div className="flex h-full w-full flex-grow flex-col justify-between gap-8 pb-10 sm:flex-row">
          {images &&
            images.map(
              (
                image:
                  | Entry<
                      TypeImageSkeleton,
                      "WITHOUT_UNRESOLVABLE_LINKS",
                      string
                    >
                  | undefined,
              ) => (
                <div className="flex w-full flex-col gap-2" key={image?.sys.id}>
                  <Image
                    loader={customImageLoader}
                    src={image?.fields.image?.fields?.file?.url ?? ""}
                    alt={image?.fields.image?.fields?.description ?? ""}
                    width={
                      image?.fields.image?.fields?.file?.details?.image
                        ?.width ?? 437
                    }
                    height={
                      image?.fields.image?.fields?.file?.details?.image
                        ?.height ?? 590
                    }
                    className="h-auto max-h-[448px] w-full max-w-[360px] rounded-lg object-cover sm:max-w-none"
                    loading="lazy"
                  />
                  <H3 className="text-brand-beige-regular pt-2 text-3xl">
                    {image?.fields.label ?? ""}
                  </H3>
                  <P className="text-brand-beige-regular !mt-0 w-full text-lg">
                    {image?.fields.description ?? ""}
                  </P>
                </div>
              ),
            )}
        </div>

        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="zest"
            className="w-full sm:w-auto"
            tracking={{
              button_content: cta?.fields.buttonText ?? "",
              button_name: "Studio McGee Values CTA",
              flow_name: segmentFlow,
            }}
            onClick={() => {
              if (!cta?.fields.url) return;
              router.push(cta.fields.url);
            }}
          >
            {cta?.fields.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}
