"use client";

import { TypeListItemCollection } from "@contentful/types";
import { notEmpty } from "@yardzen/js-util";
import { SegmentFlows } from "@yardzen/next-client-util";
import { Badge, cn, H1 } from "@yardzen/ui";
import { cva } from "class-variance-authority";
import { ListItem } from "./ListItem";
import { ListItemCollectionAccordian } from "./ListItemCollectionAccordian";

export function ListItemCollection({
  listItemCollection,
  className,
  listItemsClassName,
  listItemsContainerClassName,
  listItemHeadingClassName,
  listItemSubHeadingClassName,
  listItemIconClassName,
  listItemIconWrapperClassName,
  iconSize,
  segmentFlow,
  listItemsAtBottom,
  hideCollectionHeader,
  disableDynamicColumns,
}: {
  listItemCollection: TypeListItemCollection<"WITHOUT_UNRESOLVABLE_LINKS">;
  className?: string;
  listItemsClassName?: string;
  listItemsContainerClassName?: string;
  listItemHeadingClassName?: string;
  listItemSubHeadingClassName?: string;
  listItemIconClassName?: string;
  listItemIconWrapperClassName?: string;
  iconSize?: "sm" | "md" | "lg";
  segmentFlow?: SegmentFlows;
  listItemsAtBottom?: boolean;
  hideCollectionHeader?: boolean;
  disableDynamicColumns?: boolean;
}) {
  const { listItems, eyebrow, heading, variant, interactivity } =
    listItemCollection.fields ?? {};

  const dynamicColumns =
    listItems && listItems.length > 1 && !disableDynamicColumns;

  const variantResolved = variant ?? "icon-left";
  const iconVariants = cva("", {
    variants: {
      columnClass: {
        "icon-above": "grid grid-cols-3 gap-4 max-md:grid-cols-2",
        "icon-left": `mt-1 md:mt-4 grid gap-3 md:gap-2 p-2 md:pb-6 ${listItems && listItems.length === 1 ? "grid-cols-1" : dynamicColumns ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-1" : "lg:grid-cols-2"} ${listItemsAtBottom ? "h-full content-end" : ""}`,
      },
      wrapperClass: {
        "icon-above": "flex flex-col items-center mt-0 mb-8 md:mb-10",
        "icon-left": "flex flex-col lg:col-span-1 h-full",
      },
      badgeClass: {
        "icon-above":
          "bg-brand-moss-greens-10 my-4 w-fit bg-opacity-50 text-center text-base font-normal mx-auto",
        "icon-left":
          "bg-brand-moss-greens-10 my-4 w-fit bg-opacity-50 text-center text-base font-normal font-semibold md:flex",
      },
    },
  });

  return interactivity === "Display Image/Subheading on hover/click" ? (
    <ListItemCollectionAccordian
      listItemCollection={listItemCollection}
      listItemHeadingClassName={listItemHeadingClassName}
      listItemSubHeadingClassName={listItemSubHeadingClassName}
      segmentFlow={segmentFlow}
    />
  ) : (
    <div
      className={cn(iconVariants({ wrapperClass: variantResolved }), className)}
    >
      {eyebrow && (
        <Badge
          className={iconVariants({ badgeClass: variantResolved })}
          variant="secondary"
          hover={false}
        >
          {eyebrow}
        </Badge>
      )}
      {heading && !hideCollectionHeader && (
        <H1 className="text-brand-primary-darker">{heading}</H1>
      )}
      <div
        className={cn(
          iconVariants({ columnClass: variantResolved }),
          listItemsClassName,
        )}
      >
        {listItems &&
          listItems
            .filter(notEmpty)
            .map((listItem, i) => (
              <ListItem
                key={`list-item-${i}`}
                listItem={listItem}
                variant={variant}
                iconClassName={listItemIconClassName}
                iconWrapperClassName={listItemIconWrapperClassName}
                iconSize={iconSize}
                headingClassName={listItemHeadingClassName}
                subHeadingClassName={listItemSubHeadingClassName}
                className={listItemsContainerClassName}
              />
            ))}
      </div>
    </div>
  );
}
