import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";
import type { TypeCtaSkeleton } from "./TypeCta";
import type { TypeImageSkeleton } from "./TypeImage";
import type { TypeListItemCollectionSkeleton } from "./TypeListItemCollection";
import type { TypePackageModificationItemSkeleton } from "./TypePackageModificationItem";

export interface TypePackageDetailItemFields {
  name?: EntryFieldTypes.Symbol;
  primaryEyebrow?: EntryFieldTypes.Symbol;
  heading?: EntryFieldTypes.Symbol;
  fullPackageName?: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.RichText;
  packagePath?: EntryFieldTypes.Symbol;
  linkCta?: EntryFieldTypes.Symbol;
  secondaryEyebrow?: EntryFieldTypes.Symbol;
  price?: EntryFieldTypes.Number;
  promoPrice?: EntryFieldTypes.Number;
  numberOfInstallments?: EntryFieldTypes.Integer;
  pricePerInstallment?: EntryFieldTypes.Number;
  pricePresentation?: EntryFieldTypes.Symbol<"Above CTA" | "Next to Header">;
  listItems?: EntryFieldTypes.EntryLink<TypeListItemCollectionSkeleton>;
  cta?: EntryFieldTypes.EntryLink<TypeCtaSkeleton>;
  packagesPageCta?: EntryFieldTypes.EntryLink<TypeCtaSkeleton>;
  modificationOptions?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypePackageModificationItemSkeleton>
  >;
  icon1?: EntryFieldTypes.Symbol;
  icon2?: EntryFieldTypes.Symbol;
  previewImage?: EntryFieldTypes.EntryLink<TypeImageSkeleton>;
}

export type TypePackageDetailItemSkeleton = EntrySkeletonType<
  TypePackageDetailItemFields,
  "packageDetailItem"
>;
export type TypePackageDetailItem<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypePackageDetailItemSkeleton, Modifiers, Locales>;

export function isTypePackageDetailItem<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypePackageDetailItem<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "packageDetailItem";
}
